// Component's Classes
import { InputBase } from '@/Components/Modules/2/DataForm/Classes/InputBase'

// Class Export
export class InputText extends InputBase {
	key: string
	label: string
	
	constructor(key: string, label: string) {
		super()
		this.key = key
		this.label = label

		// Internal Calls
		this._setType('text')
		this.setState(this._defaultInputTextValidator)

}
		private _defaultInputTextValidator(self: any) {
			let aux = self.value.trim()
			if (aux === '') return null
			if (aux.length < 3 || aux.length > 120) return false
			return true
		}

	
}