// Classes
import { PrimitiveTools } from '@/Classes/Static/PrimitiveTools'

// Constants
import { AppValues } from '@/Constants/Global/AppValues'
import { VueRouter } from '@/Constants/Global/VueRouter'

// Dependencies
import Joi from 'joi'

// Namespace Export
export namespace Module1 {
	export namespace M10 {
		export const ModulesList: any = {
			[AppValues.ObjectIds.Module10]: { _params: { index: 0, visible: false } },
	
			[AppValues.ObjectIds.Module20]: {
				...PrimitiveTools.Objects.newModuleStructure(1, true, 'Administración', 'Module20', '003B49', '099CC0'),
				description: 'Gestión de Usuarios, Roles, Permisos, entre otros',
				path: VueRouter.Modules.View20.NAME
			},
	
			[AppValues.ObjectIds.Module30]: {
				...PrimitiveTools.Objects.newModuleStructure(2, false, 'Servicios', 'Module30', '003B49', '099CC0'),
				description: 'Solicitudes, Ordenes de Servicio y Seguimiento Mantención',
				path: VueRouter.Modules.View30.NAME
			},
	
			[AppValues.ObjectIds.Module40]: {
				...PrimitiveTools.Objects.newModuleStructure(3, true, 'CheckLists', 'Module40', 'CF9800', 'F6D568'),
				description: 'Inspecciones de Equipos',
				path: VueRouter.Modules.View40.NAME
			},
	
			[AppValues.ObjectIds.Module41]: {
				...PrimitiveTools.Objects.newModuleStructure(4, true, 'Setup CheckLists', 'ModuleXX', 'CF9800', 'F6D568'),
				description: 'Setup CheckList',
				path: VueRouter.Modules.View41.NAME
			}
		}
	}

	export namespace M11 {
		export const ModulesList: any = {
			[AppValues.ObjectIds.Module40]: {
				...PrimitiveTools.Objects.newModuleStructure(1, true, 'CheckLists', 'Module40', 'CF9800', 'F6D568'),
				description: 'Inspecciones de Equipos',
				path: VueRouter.Modules.View40.NAME
			}
		}

		export const SidePanelItems = [
			{ action: VueRouter.Modules.View11.NAME, label: 'Sistemas', icon: 'dice-d6', selected: true },
			{ action: VueRouter.Modules.View12.NAME, label: 'Gestión Operadores', icon: 'users-cog', selected: false },
			{ action: VueRouter.Modules.View13.NAME, label: 'Gestión Ubicaciones Internas', icon: 'users-cog', selected: false }
		]
	}

	export namespace M12 {
		const JoiSchemaOperatorBodyNames = {
			_idStorage:     'Ubicación',
			rut:            'Rut',
			name:           'Nombre',
			pLastName:      '1er Apellido',
			mLastName:      '2do Apellido',
			password:       'Contraseña',
			dateBirth:      'Fecha Nacimiento',
			isValid:        'Estado',
			position:       'Cargo',
			licenses:       'Licencia',
			certifications: 'Certificación',
		}

		export namespace JoiSchemas {
			export const AddOperator = Joi.object({
				_idStorage:     Joi.string().hex().required().label(JoiSchemaOperatorBodyNames._idStorage),
				certifications: Joi.array(),
				dateBirth:      Joi.string().allow('', null).label(JoiSchemaOperatorBodyNames.dateBirth),
				licenses:       Joi.array(),
				mLastName:      Joi.string().allow('').min(3).max(32).label(JoiSchemaOperatorBodyNames.mLastName),
				name:           Joi.string().required().min(3).max(32).label(JoiSchemaOperatorBodyNames.name),
				rut:            Joi.string().required().label(JoiSchemaOperatorBodyNames.rut),
				password:       Joi.string().required().label(JoiSchemaOperatorBodyNames.password),
				pLastName:      Joi.string().required().min(3).max(32).label(JoiSchemaOperatorBodyNames.pLastName),
				position:       Joi.string().required().label(JoiSchemaOperatorBodyNames.position)
			})
		
			export const UpdateOperator = Joi.object({
				_idOperator:    Joi.string().hex().required(),
				_idStorage:     Joi.string().hex().required().label(JoiSchemaOperatorBodyNames._idStorage),
				certifications: Joi.array(),
				dateBirth:      Joi.string().allow('', null).label(JoiSchemaOperatorBodyNames.dateBirth),
				isValid:        Joi.boolean().required().label(JoiSchemaOperatorBodyNames.isValid),
				licenses:       Joi.array(),
				mLastName:      Joi.string().allow('').min(3).max(32).label(JoiSchemaOperatorBodyNames.mLastName),
				name:           Joi.string().required().min(3).max(32).label(JoiSchemaOperatorBodyNames.name),
				password:       Joi.string(),
				pLastName:      Joi.string().required().min(3).max(32).label(JoiSchemaOperatorBodyNames.pLastName),
				position:       Joi.string().required().label(JoiSchemaOperatorBodyNames.position)
			})
		}
	}

	export namespace M13 {
		enum TempMenuBarKeyOptions {
			INTERNAL_STORAGE,
			INTERNAL_ASSIGNMENT
		}

		export namespace Collections {
			export const MenuBarOptions = (shortened: boolean) => [
				{
					key: TempMenuBarKeyOptions.INTERNAL_STORAGE,
					icon: 'map-marker-alt', label: 'Ubicaciones'
				},
				{
					key: TempMenuBarKeyOptions.INTERNAL_ASSIGNMENT,
					icon: 'list', label: 'Asignación'
				},
			]
		}

		export namespace Defaults {
			export const MenuBarKeyOptions = {
				...TempMenuBarKeyOptions
			}
		}

		const JoiSchemaInternalStorageBodyNames = {
			_idCreator: '_idCreator',
			_idStorage: '_idStorage',
			code:       'Código Ubicación',
			equipments: 'Equipos Asignados',
			name:       'Descripción Ubicación'
		}

		export namespace JoiSchemas {
			export const AddInternalStorage = Joi.object({
				_idCreator: Joi.string().hex().required().label(JoiSchemaInternalStorageBodyNames._idCreator),
				_idStorage: Joi.string().hex().required().label(JoiSchemaInternalStorageBodyNames._idStorage),
				code:       Joi.string().required().label(JoiSchemaInternalStorageBodyNames.code),
				equipments: Joi.array().items(Joi.string().hex()).min(0).required().label(JoiSchemaInternalStorageBodyNames.equipments),
				name:       Joi.string().required().label(JoiSchemaInternalStorageBodyNames.name)
			})

			export const UpdateEquipmentZone = Joi.object({
				_idEquipment: Joi.string().hex().required(),
				_idZone:      Joi.string().hex().required(),
			})

			export const UpdateInternalStorage = Joi.object({
				_idZone:    Joi.string().hex().required(),
				equipments: Joi.array().items(Joi.string().hex()).min(0).required().label(JoiSchemaInternalStorageBodyNames.equipments),
				isValid:    Joi.boolean().required(),
				name:       Joi.string().required().label(JoiSchemaInternalStorageBodyNames.name)
			})
		}
	}
}