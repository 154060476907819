// Component's Classes
import { BaseElement } from '@/Components/Modules/2/DataForm/Classes/BaseElement'

// Class Export
export class Select extends BaseElement {
	key: string
	label: string
	options: any[]
	type: string
	selected: any

	constructor(key: string, label: string, options: Array<any> = []) {
		super()
		this.key = key
		this.label = label
		this.options = options
		this.type = 'select'
		this.selected = null
		this.setState(this._defaultSelectStateValidator)
	}

	private _defaultSelectStateValidator(self: Select) {
		if (self?.selected === null) return null
		return true
	}
}