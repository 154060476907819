// Dependencies
import Vue from 'vue'

// Component Extend
const PeriodPicker = Vue.extend({
	name: 'PeriodPicker',

	props: {
		label: { type: String, default: '' },
		optional: { type: Boolean, default: false },
		state: Boolean
	},

	data: function() {
		return {
			month: undefined,
			year: undefined
		}
	},

	mounted: function() {
		this.setDefault()
	},

	methods: {
		_getMonthState: function() {
			if (this.month === undefined) return null
			return true
		},

		_getYearState: function() {
			if (this.year === undefined) return null
			return true
		},

		clearSelection: function() {
			this.month = undefined
			this.year  = undefined
		},

		getDate: function() {
			const { month, year } = this
			if (month && year) return `${ month } - ${ year }`
		},

		getMonths: function() {
			return [
				'Enero',   'Febrero',   'Marzo',
				'Abril',   'Mayo',      'Junio',
				'Julio',   'Agosto',    'Septiembre',
				'Octubre', 'Noviembre', 'Diciembre'
			]
		},

		getYears: function() {
			return ['2021', '2022', '2023']
		},

		setDefault: function() {
			// Obtener el Mes y Año actual.
			const date = new Date()
			const month = date.getMonth()
			const year = `${date.getFullYear()}`

			// Seleccionar las opciones por defecto.
			const months = this.getMonths()
			this.setSelection(months[month], year)
		},

		setSelection: function(month: string, year: string) {
			this.month = month
			this.year = year
		},

		onInput: function() {
			this.$emit('input', [this.month, this.year])
		}
	}
})

// Exports
export default PeriodPicker
export type PeriodPickerRef = InstanceType<typeof PeriodPicker>