// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { PrimitiveTools }   from '@/Classes/Static/PrimitiveTools'

// Constants
import { AppValues } from '@/Constants/Global/AppValues'
import { Component } from '@/Constants/Global/Component'
import { Module1 }   from '@/Constants/Modules/Module1'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinBase       from '@/Mixins/MixinBase'
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Store
import Store from '@/Store/Global/Default'

// Component Extend
const PermissionsForm = VueMixins(MixinBase, MixinComponent, MixinResponsive).extend({
	name: 'PermissionsForm',

	props: {
		show: Boolean
	},

	data: function() {
		return {
			auxPermissions: [],
			groups: {},
			permissions: [],
			systems: [],
			states: {
				action: Component.Actions.INSERT,
				checkBoxesDisabled: false,
				isFetching: false,
				isMobile: false,
				isRevertButtonEnabled: false,
				permissionDefaultValue: false,
				roleId: undefined,
				roleName: '',
				roleStatus: true
			}
		}
	},

	computed: {
		_isPermissionCheckBoxDisabled: function(): boolean {
			return (this.states.isFetching || this.states.checkBoxesDisabled) as boolean
		}
	},

	methods: {
		_checkPermissionValidations: function(_idPermission: ObjectId, system: any, module: any) {
			if (!this._getPermission(_idPermission)) return false
			const systemAndModuleDisplays = system.display && module.display
			return systemAndModuleDisplays
		},

		_getCheckedGroupState: function(module: any, privilegeName: string) {
			// Array con los ID de los Permisos del Modulo.
			const modulePermissionsIds = (<any> this.groups)[module._id].ids
			const firstPermissionId = modulePermissionsIds[0]
			const firstPrivilegeValue = this.permissions.find((x: any) => x._idPermission === firstPermissionId)?.privileges[privilegeName]

			// Cualquier valor que sea distinto al primer Permiso, cuenta como 'Indeterminado'.
			for (let i = 1; i < modulePermissionsIds.length; i++) {
				const _idPermission = modulePermissionsIds[i]
				const privilegeValue = this.permissions.find((x: any) => x._idPermission === _idPermission)?.privileges[privilegeName]

				if (firstPrivilegeValue !== privilegeValue) {
					module.indeterminate[privilegeName] = true
					return
				}
			}

			module.privileges[privilegeName] = firstPrivilegeValue
			module.indeterminate[privilegeName] = false
		},

		_getDisplayIcon: function(display: boolean) {
			return display ? 'eye' : 'eye-slash'
		},

		_getPermission: function(_idPermission: ObjectId) {
			return this.permissions.find((x: any) => x._idPermission === _idPermission)
		},

		_getPermissionPrefixToName: function(_idPermission: ObjectId) {
			const p = this._getPermission(_idPermission)
			switch (p.prefix) {
				case 'F': return 'Formulario'
				case 'I': return 'Informe'
				case 'S': return 'Sistema'
			}
		},

		_getSubmitButtonText: function() {
			return this.states.action === Component.Actions.INSERT ? 'Ingresar' : 'Actualizar'
		},
		
		_resolvePrefixStyle: function(prefix: string) {
			let color: string
			switch (prefix) {
				case 'F': color = 'green'; break
				case 'I': color = 'blue'; break
				case 'S': color = 'orange'; break
			}
			return `
				border-color: var(--${ color });
				color: var(--${ color });
			`
		},
		
		_revertPermissionsChanges: function(name: string, overrideTo: boolean) {
			// Por obvias razones, el largo de ambos arrays debe ser el mismo.
			const reset = (name: string, overrideTo: boolean) => {
				const { auxPermissions, permissions } = this
				if (auxPermissions.length === permissions.length) {
					for (let i = 0; i < permissions.length; i++) {
						permissions[i].privileges[name] = PrimitiveTools.Booleans.isValid(overrideTo)
							? PrimitiveTools.Booleans.toBoolean(overrideTo)
							: Boolean(auxPermissions[i].privileges[name])
					}
				}
			}

			// Restablecer los valores.
			reset(name, overrideTo)
			this.setStates<PermissionsFormRef['states']>({ isRevertButtonEnabled: PrimitiveTools.Booleans.isValid(overrideTo) })

			// Comprobar los estados 'Indeterminate' de los Modulos.
			for (const system of this.systems) {
				for (const module of system.modules) {
					this._getCheckedGroupState(module, 'read')
					this._getCheckedGroupState(module, 'write')
				}
			}
		},

		clearAll: function() {
			this.setStates<PermissionsFormRef['states']>({ roleId: undefined, roleName: '', roleStatus: true })
			this._revertPermissionsChanges('read', false)
			this._revertPermissionsChanges('write', false)
		},

		setSystems: function(systems: Array<any>) {
			if (!PrimitiveTools.Arrays.isInvalidOrEmpty(systems)) {
				// Valor por defecto de los Permisos al ser inicializados.
				const { permissionDefaultValue } = this.states

				// Por precaución, vaciar ambos Arrays.
				this.auxPermissions = []
				this.permissions = []

				// Filtar los Sistemas que actualmente no son mostrados.
				const _systems = systems.filter((x) => {
					// IDs de los Sistemas Ocultos.
					if (x._idSystem === AppValues.ObjectIds.Module10) return true
					if (x._idSystem === AppValues.ObjectIds.Module20) return true

					// Resto de Sistemas.
					const system = Module1.M10.ModulesList[x._idSystem]
					if (system) return system._params.selectable
				})

				const PermissionsSystemOrder: { [key: string]: number } = {
					/*SYS01 */ '606a23a1a758634128c7bd73': 0,
					/*SYS02 */ '606a2401a758634128c7bd74': 1,
					/*SYS03 */ '606a2450a758634128c7bd75': 2,
					/*SYS04 */ '606a24b0a758634128c7bd76': 3,
					/*SYS05 */ '606a250ea758634128c7bd77': 4,
					/*SYS06 */ '606a2523a758634128c7bd78': 5,
					/*SYS07 */ '6092cf0c53b1f928ec30f2ca': 6,
					/*SYS08 */ '60ff0eed51cbc332fc5c24e8': 7,
					/*SYS10 */ '61e8652b9082fe99eb5f50fd': 8
				}

				// Ordenar los Sistemas.
				_systems.sort((a: any, b: any) => {
					const systemA = PermissionsSystemOrder[a._idSystem]
					const systemB = PermissionsSystemOrder[b._idSystem]
					return systemA - systemB
				})

				// Iterar por los distintos sistemas.
				for (const system of _systems) {
					// Modulos.
					for (const module of system.modules) {
						// Los Ids de los Permisos de un mismo grupo serán almacenados en una propiedad.
						(<any> this.groups)[module._id] = { checked: false, ids: [] }

						// Permisos.
						for (const permission of module.permissions) {
							// Agregar el correspondiente Permiso al Modulo que corresponde.
							(<any> this.groups)[module._id].ids.push(permission)

							// Los datos de un Permiso deben ser obtenidos desde el Store.
							const storedPermission = Store.getters.getStoredPermissionById(permission)

							// Agregarlo solo si es un Permiso valido.
							if (storedPermission) {
								// Construir la estructura del objeto para representar el Permiso visualmente.
								const { action, name, prefix } = storedPermission
								this.auxPermissions.push({ _idPermission: permission, action, name, prefix, privileges: { read: permissionDefaultValue, write: permissionDefaultValue } })
								this.permissions.push({ _idPermission: permission, action, name, prefix, privileges: { read: permissionDefaultValue, write: permissionDefaultValue } })
							}
						}
					}
				}

				// Almacenar la estructura correspondiente a los Sistemas.
				this.systems = _systems
			}
		},

		updateAuxPermissionsStatus: function() {
			// Por obvias razones, el largo de ambos arrays debe ser el mismo.
			const reset = (name: string) => {
				const { auxPermissions, permissions } = this
				if (auxPermissions.length === permissions.length) {
					for (let i = 0; i < auxPermissions.length; i++) {
						auxPermissions[i].privileges[name] = this.states.permissionDefaultValue
					}
				}
			}
			reset('read')
			reset('write')
		},

		updatePermissionsStatus: function(externals: Array<any>) {
			const updater = (arr: Array<any>) => {
				for (const permission of arr) {
					for (const external of externals) {
						if (permission._idPermission === external._id) {
							permission.privileges = { ...external.privileges }
							break
						}
					}
				}
			}
			updater(this.auxPermissions)
			updater(this.permissions)
			this.setStates<PermissionsFormRef['states']>({ isRevertButtonEnabled: false })
		},

		onPFButtonClick: function(action: string) {
			DevelopmentTools.printWarn('[PermissionsForm]:onPFButtonClick() event triggered')
			switch (action) {
				case 'revert':
					this._revertPermissionsChanges('read', null)
					this._revertPermissionsChanges('write', null)
					break
				case 'reset-reads' : return this._revertPermissionsChanges('read', this.states.permissionDefaultValue)
				case 'reset-writes': return this._revertPermissionsChanges('write', this.states.permissionDefaultValue)
			}
			this.$emit('onPFButtonClick', action)
		},

		onPFExpCollClick: function(state: boolean) {
			if (!PrimitiveTools.Arrays.isInvalidOrEmpty(this.systems)) {
				this.systems.forEach((system: any) => {
					system.display = state
					system.modules.forEach((mod: any) => {
						mod.display = state
					})
				})
			}
		},

		onPFPermissionChanged: function(permissionId: ObjectId, privilegeName: string, checked: boolean) {
			this.setStates<PermissionsFormRef['states']>({ isRevertButtonEnabled: true })
		},

		onPFUpdateGroupStates: function(moduleID: ObjectId, privilegeName: string, checked: boolean) {
			for (const permissionID of (<any> this.groups)[moduleID].ids) {
				for (const permission of this.permissions) {
					if (permissionID === permission._idPermission) {
						permission.privileges[privilegeName] = checked
						break
					}
				}
			}
			this.setStates<PermissionsFormRef['states']>({ isRevertButtonEnabled: true })
		}
	}
})

// Exports
export default PermissionsForm
export type PermissionsFormRef = InstanceType<typeof PermissionsForm>