// Component's Classes
import { InputBase } from '@/Components/Modules/2/DataForm/Classes/InputBase'

// Class Export
export class InputPassword extends InputBase {
	key: string
	label: string
	visibility: boolean
	
	constructor(key: string, label: string) {
		super()
		this.key = key
		this.label = label
		this.visibility = false
		this._setType('password')
		this.setState(super._defaultInputStateValidator)
	}
}