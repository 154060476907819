// Component's Classes
import { InputBase } from '@/Components/Modules/2/DataForm/Classes/InputBase'

// Class Export
export class InputEmail extends InputBase {
	key: string
	label: string

	constructor(key: string, label: string) {
		super()
		this.key = key
		this.label = label
		this._setType('email')
		this.setState(this._defaultInputEmailValidator)
	}

	private _defaultInputEmailValidator(self: any) {
		let aux = self.value.trim()
		if (aux === '') return null
		return /^[a-z0-9]+(.+)@[a-z0-9]+\.[a-z0-9]+$/i.test(aux)
	}
}