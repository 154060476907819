// Component's Classes
import { InputBase } from '@/Components/Modules/2/DataForm/Classes/InputBase'

// Class Export
export class InputPhone extends InputBase {
	key: string
	label: string

	constructor(key: string, label: string) {
		super()
		this.key = key
		this.label = label
		this._setType('phone')
		this.setFormatter(this._defaultInputPhoneFormatter)
		this.setState(this._defaultInputPhoneStateValidator)
	}

	private _defaultInputPhoneFormatter(value: string) {
		value = value.replace(/[^0-9]/g, '').trim()
		return value.replace(/(\d{1})(\d{4})(\d{4})/, "$1 $2 $3")
	}

	private _defaultInputPhoneStateValidator(self: InputPhone) {
		let aux = self.value.trim()
		if (aux === '') return null
		return /^[2|9][\s][0-9]{4}[\s][0-9]{4}$/i.test(aux)
	}

	public getValue() {
		return this.value !== '' ? `+56 ${this.value}` : ''
	}
}