// Constants
import { VueRouter } from '@/Constants/Global/VueRouter'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinResponsive from '@/Mixins/MixinResponsive'

// Component Extend
const AdminNavigation = VueMixins(MixinResponsive).extend({
	name: 'AdminNavigation',

	props: {
		selected: Number
	},

	data: function() {
		return {
			items: [
				{ name: VueRouter.Modules.View20.NAME, label: 'Usuarios Clientes' },
				{ name: VueRouter.Modules.View21.NAME, label: 'Usuarios Internos' },
				{ name: VueRouter.Modules.View22.NAME, label: 'Ubicaciones' },
				{ name: VueRouter.Modules.View23.NAME, label: 'Configuraciones'} ,
			]
		}
	},

	methods: {
		onItemClick: function({ name }: any) {
			this.$router.push({ name })
		}
	}
})

// Exports
export default AdminNavigation
export type AdminNavigationRef = InstanceType<typeof AdminNavigation>