// Constants
import { Component } from '@/Constants/Global/Component'

// Class Export
export class BaseElement {
	params: any

	constructor() {
		this.params = {}
	}

	public isOptional() {
		this.params.optional = true
		return this
	}

	public setPlaceHolder(placeholder: string) {
		this.params.placeholder = placeholder
		return this
	}

	public setReadOnly(readonly: Component.Actions) {
		this.params.readOnlyAt = readonly
		return this
	}

	public setState(func: Function) {
		this.params.state = func
		return this
	}

	public setValidation(fn: (value: any) => boolean) {
		this.params.validation = fn
		return this
	}

	public setVisible(visible: Component.Actions) {
		this.params.visible = visible
		return this
	}
}