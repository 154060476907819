// Dependencies
import Joi from 'joi'

// Namespace Export
export namespace Module2 {
	export namespace JoiSchemas {
		const JoiSchemaLabels = {
			_idAdminCompany: '_idAdminCompany',
			_idCompany:  'Empresa',
			_idRole:     'Rol',
			_idWorkArea: 'Área de Trabajo',
			rut:         'Rut',
			name:        'Nombre',
			pLastName:   'Apellido Paterno',
			mLastName:   'Apellido Materno',
			email:       'Correo',
			phone:       'Fono',
			password:    'Contraseña',
			storages:    'Ubicaciones',
			permissions: 'Permisos'
		}

		export const AddUser = Joi.object({
			_idAdminCompany: Joi.string().required().label(JoiSchemaLabels._idAdminCompany),
			_idCompany:  Joi.string().hex().required().label(JoiSchemaLabels._idCompany),
			_idRole:     Joi.string().hex().required().label(JoiSchemaLabels._idRole),
			_idWorkArea: Joi.string().hex().required().label(JoiSchemaLabels._idWorkArea),
			rut:         Joi.string().min(9).max(12).label(JoiSchemaLabels.rut),
			name:        Joi.string().label(JoiSchemaLabels.name),
			pLastName:   Joi.string().required().label(JoiSchemaLabels.pLastName),
			mLastName:   Joi.string().allow(''),
			email:       Joi.string().email({ tlds:{ allow: false } }).required().label(JoiSchemaLabels.email),
			phone:       Joi.array(),
			password:    Joi.string().required().label(JoiSchemaLabels.password),
			storages:    Joi.array().items(Joi.string().hex()),
			permissions: Joi.array()
		})
		
		export const UpdateUser = Joi.object({
			_idUser:     Joi.string().hex().required(),
			_idRole:     Joi.string().hex().required().label(JoiSchemaLabels._idRole),
			_idWorkArea: Joi.string().hex().required().label(JoiSchemaLabels._idWorkArea),
			rut:         Joi.string().min(9).max(12).label(JoiSchemaLabels.rut),
			name:        Joi.string().label(JoiSchemaLabels.name),
			pLastName:   Joi.string().label(JoiSchemaLabels.pLastName),
			mLastName:   Joi.string().allow(''),
			email:       Joi.string().email({ tlds:{ allow: false } }).label(JoiSchemaLabels.email),
			phone:       Joi.array(),
			storages:    Joi.array().items(Joi.string().hex()),
			isValid:      Joi.boolean().required(),
			permissions: Joi.array()
		})
	}

	export namespace M22 {
		export namespace JoiSchemas {
			const JoiSchemaCompanyLabels = {
				_idAdminCompany: '_idAdminCompany',
				_idCompany:      '_idCompany',
				code:            'Código',
				rut:             'Rut',
				name:            'Razón Social',
				address:         'Dirección de Facturación',
				region:          'Región',
				commune:         'Comuna',
				type:            'Tipo',
				isClient:        'Es Cliente',
				isValid:         'Esta Activa'
			}
			
			const JoiSchemaStorageLabels = {
				_idCompany:   '_idCompany',
				_idTechnical: '_idTechnical',
				_idUserAdmin: '_idUserAdmin',
				codeLocation: 'codeLocation',
				code:         'Código',
				name:         'Nombre',
				address:      'Dirección',
				region:       'Región',
				commune:      'Comuna'
			}

			export const AddCompany = Joi.object({
				_idAdminCompany: Joi.string().required().label(JoiSchemaCompanyLabels._idAdminCompany),
				code:            Joi.string().required().label(JoiSchemaCompanyLabels.code),
				rut:             Joi.string().required().label(JoiSchemaCompanyLabels.rut),
				name:            Joi.string().required().label(JoiSchemaCompanyLabels.name),
				address:         Joi.string().required().label(JoiSchemaCompanyLabels.address),
				region:          Joi.string().required().label(JoiSchemaCompanyLabels.region),
				commune:         Joi.string().required().label(JoiSchemaCompanyLabels.commune),
				type:            Joi.string().required().label(JoiSchemaCompanyLabels.type),
				isClient:        Joi.boolean().required().label(JoiSchemaCompanyLabels.isClient),
			})
			
			export const UpdateCompany = Joi.object({
				_idCompany: Joi.string().hex().required(),
				name:       Joi.string().required().label(JoiSchemaCompanyLabels.name),
				address:    Joi.string().required().label(JoiSchemaCompanyLabels.address),
				commune:    Joi.string().required().label(JoiSchemaCompanyLabels.commune),
				region:     Joi.string().required().label(JoiSchemaCompanyLabels.region),
				isValid:    Joi.boolean().required().label(JoiSchemaCompanyLabels.isValid)
			})
	
			export const AddStorage = Joi.object({
				_idCompany:   Joi.string().hex().required().label(JoiSchemaStorageLabels._idCompany),
				_idTechnical: Joi.string().hex().allow(null).label(JoiSchemaStorageLabels._idTechnical),
				_idUserAdmin: Joi.string().hex().allow(null).label(JoiSchemaStorageLabels._idUserAdmin),
				codeLocation: Joi.string().required().label(JoiSchemaStorageLabels.codeLocation),
				code:         Joi.string().required().label(JoiSchemaStorageLabels.code),
				name:         Joi.string().required().label(JoiSchemaStorageLabels.name),
				address:      Joi.string().min(4).max(255).required().label(JoiSchemaStorageLabels.address),
				commune:      Joi.string().required().label(JoiSchemaStorageLabels.commune),
				region:       Joi.string().required().label(JoiSchemaStorageLabels.region)
			})
			
			export const UpdateStorage = Joi.object({
				_idStorage:   Joi.string().hex().required(),
				_idCompany:   Joi.string().hex().required().label(JoiSchemaStorageLabels._idCompany),
				_idTechnical: Joi.string().hex().allow(null).label(JoiSchemaStorageLabels._idTechnical),
				_idUserAdmin: Joi.string().hex().allow(null).label(JoiSchemaStorageLabels._idUserAdmin),
				code:         Joi.string().required().label(JoiSchemaStorageLabels.code),
				name:         Joi.string().required().label(JoiSchemaStorageLabels.name),
				address:      Joi.string().min(4).max(255).required().label(JoiSchemaStorageLabels.address),
				region:       Joi.string().required().label(JoiSchemaStorageLabels.region),
				commune:      Joi.string().required().label(JoiSchemaStorageLabels.commune),
				isValid:      Joi.boolean().required()
			})
		}
	}

	export namespace M23 {
		export namespace JoiSchemas {
			const JoiSchemaEquipmentLabels = {
				_idStorage:     'Ubicación',
				code:           'Código',
				hourmeter:      'Horómetro',
				isValid:        'Estado',
				mark:           'Marca',
				name:           'Descripción',
				groupArticle:   'Grupo Artículo',
				groupEquipment: 'Grupo Equipo',
				serie:          'Serie',
				typeArticle:    'Tipo Artículo',
				typeEquipment:  'Tipo Equipo',
				year:           'Año'
			}
			
			const JoiSchemaRoleLabels = {
				_idAdminCompany: '_idAdminCompany',
				isValid:         'Esta Activo',
				name:            'Rol',
				permissions:     'Permisos'
			}
			
			const JoiSchemaWorkAreaLabels = {
				_idAdminCompany: '_idAdminCompany',
				_idLeader:       'Líder',
				isValid:         'Esta Activa',
				name:            'Nombre'
			}

			export const AddEquipment = Joi.object({
				_idStorage:     Joi.string().hex().required().label(JoiSchemaEquipmentLabels._idStorage),
				code:           Joi.string().required().label(JoiSchemaEquipmentLabels.code),
				serie:          Joi.string().required().label(JoiSchemaEquipmentLabels.serie),
				name:           Joi.string().required().label(JoiSchemaEquipmentLabels.name),
				mark:           Joi.string().required().allow('').label(JoiSchemaEquipmentLabels.mark),
				groupArticle:   Joi.string().required().label(JoiSchemaEquipmentLabels.groupArticle),
				typeArticle:    Joi.string().required().allow('').label(JoiSchemaEquipmentLabels.typeArticle),
				groupEquipment: Joi.string().required().allow('').label(JoiSchemaEquipmentLabels.groupEquipment),
				typeEquipment:  Joi.string().required().allow('').label(JoiSchemaEquipmentLabels.typeEquipment),
				year:           Joi.number().required().label(JoiSchemaEquipmentLabels.year),
				hourmeter:      Joi.number().allow(0).label(JoiSchemaEquipmentLabels.hourmeter)
			})
			
			export const UpdateEquipment = Joi.object({
				_idEquipment:   Joi.string().hex().required(),
				_idStorage:     Joi.string().hex().required().label(JoiSchemaEquipmentLabels._idStorage),
				serie:          Joi.string().required().label(JoiSchemaEquipmentLabels.serie),
				name:           Joi.string().required().label(JoiSchemaEquipmentLabels.name),
				mark:           Joi.string().required().allow('').label(JoiSchemaEquipmentLabels.mark),
				groupArticle:   Joi.string().required().label(JoiSchemaEquipmentLabels.groupArticle),
				typeArticle:    Joi.string().required().allow('').label(JoiSchemaEquipmentLabels.typeArticle),
				groupEquipment: Joi.string().required().allow('').label(JoiSchemaEquipmentLabels.groupEquipment),
				typeEquipment:  Joi.string().required().label(JoiSchemaEquipmentLabels.typeEquipment),
				year:           Joi.number().required().label(JoiSchemaEquipmentLabels.year),
				isValid:        Joi.boolean().required().label(JoiSchemaEquipmentLabels.isValid)
			})
	
			export const AddRole = Joi.object({
				_idAdminCompany: Joi.string().hex().required().label(JoiSchemaRoleLabels._idAdminCompany),
				name:            Joi.string().required().label(JoiSchemaRoleLabels.name),
				permissions:     Joi.array().required().label(JoiSchemaRoleLabels.permissions)
			})
			
			export const UpdateRole = Joi.object({
				_idRole:     Joi.string().hex().required(),
				name:        Joi.string().required().label(JoiSchemaRoleLabels.name),
				isValid:     Joi.boolean().required().label(JoiSchemaRoleLabels.isValid),
				permissions: Joi.array().required().label(JoiSchemaRoleLabels.permissions)
			})
	
			export const AddWorkArea = Joi.object({
				_idAdminCompany: Joi.string().hex().required().label(JoiSchemaWorkAreaLabels._idAdminCompany),
				_idLeader:       Joi.string().hex().required().label(JoiSchemaWorkAreaLabels._idLeader),
				name:            Joi.string().required().label(JoiSchemaWorkAreaLabels.name)
			})
			
			export const UpdateWorkArea = Joi.object({
				_idWorkArea: Joi.string().required(),
				_idLeader:   Joi.string().hex().label(JoiSchemaWorkAreaLabels._idLeader),
				name:        Joi.string().required().label(JoiSchemaWorkAreaLabels.name),
				isValid:     Joi.boolean().required().label(JoiSchemaWorkAreaLabels.isValid)
			})
		}
	}
}